import store from '../store/index';

function LoadingComponent() {}

LoadingComponent.prototype.ocultar = async function() {
    await store.dispatch('uiState/hideLoading');
};

LoadingComponent.prototype.exibir = async function() {
    await store.dispatch('uiState/showLoading');
};

export default new LoadingComponent();
