import produto_actions from './produto-actions';
import produto_getters from './produto-getters';
import produto_mutations from './produto-mutations';

const produto_module = {
    namespaced: true,
    state: {
        produtos: []
    },
    getters: produto_getters,
    actions: produto_actions,
    mutations: produto_mutations
};

export default produto_module;
