import axios from 'axios';
import apiConfig from './apiConfig';
import store from '@/store/index';

const apiClient = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    },
    withCredentials: false
});

const HTTP_UNAUTHORIZED = 401;
const HTTP_BADREQUEST = 400;

apiClient.interceptors.request.use(
    (config) => {
        const token = store && store.getters['auth/ehAutenticacaoAnonima'] ? apiConfig.getTokenAnonimo() : apiConfig.getToken();
        if (token && config.headers['Authorization'] == null) config.headers['Authorization'] = token;

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

// TODO: Melhorar tratativa de retorno da API (400, 500, etc): https://github.com/axios/axios
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }

        if (error.message === 'Network Error') {
            //CDN3-TODO: avisar ao usuário que ele pode estar sem internet
            return Promise.reject(error);
        }

        if (error.response.status === HTTP_UNAUTHORIZED) {
            // TODO: UNAUTHORIZED, IMPLEMENTAREMOS REFRESH TOKEN? REDIRECIONAMOS PARA LOGIN OU APRESENTAREMOS MENSAGEM, PERGUNTANDO SE DESEJA LOGAR NOVAMENTE?
            return Promise.reject(error);
        } else if (error.response.status === HTTP_BADREQUEST) {
            // TODO: BAD REQUEST, CONTROLAREMOS NO APICLIENT OU RETORNA PARA A SERVICE/COMPONENT TRATAREM O RETORNO?
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

export default apiClient;
