import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router/index';
import store from './store/index';
import VueTheMask from 'vue-the-mask';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './assets/css/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSocialSharing from 'vue-social-sharing';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import helper from './common/helper';
import VueNumericInput from 'vue-numeric-input';

require('moment/locale/pt-br');
moment.locale('pt-br');

library.add(faUserSecret);

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

// TODO: OS COMPONENTES ABAIXO DEVEM SER IMPORTADOS NAS TELAS QUE OS UTILIZAM, POIS NÃO SÃO USADOS GLOBALMENTE
Vue.component('vue-multiselect', Multiselect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueTheMask);
Vue.use(helper, { router, store });

Vue.use(VueMoment, { moment });
Vue.use(VueLodash, { name: '$_', lodash: lodash });

Vue.use(VueSocialSharing);
Vue.use(VueNumericInput);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
});

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
