const balanco_mutations = {
  atualizaFiltros(state, payload) {
    state.filtros = payload;
  },
  atualizaBalancoSelecionado(state, payload) {
    state.balancoSelecionado = payload;
  },
  atualizaSecaoSelecionada(state, payload) {
    state.secaoSelecionada = payload;
  },
  setListaDeBalancos(state, payload) {
    state.listaDeBalancos = payload;
  },
  setDataCadastroBalanco(state, payload) {
    state.dadosCadastroBalanco = payload;
  },
  setEmpresasPortal(state, payload) {
    state.empresasPortal = payload;
  },
  setEmpresaAtual(state, payload) {
    state.empresaAtual = payload;
  },
  setFiltrarBalancos(state, payload) {
    state.responseFiltrarBalancos = payload;
  },
  setProdutoPesquisado(state, payload) {
    state.produto = payload;
  },
  setFinalizarSecoesResponse(state, payload) {
    state.responseFinalizarSecoes = payload;
  },
  atualizarListaItensConferidos(state, payload) {
    state.listaItensConferidos = payload;
  },
};
export default balanco_mutations;
