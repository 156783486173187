import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';
import { NOMES_PRODUTOS_API, AUTENTICACAO_TOKEN, AUTENTICACAO_WMS, AUTENTICACAO_ANONIMA } from './auth-mutations-types';

const idConfigAmbiente = process.env.VUE_APP_ID_CONFIG_AMBIENTE || null;

const registrarUrlAPIs = function(produtos) {
    apiConfig.setUrlCrmApi(produtos.filter((x) => x.Produto === 'LMX.CRM.WebApi')[0].Url);
    apiConfig.setUrlSuprimentosApi(produtos.filter((x) => x.Produto === 'LMX.Suprimentos.WebApi')[0].Url);
};

const auth_actions = {
    login: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/Login';
            const requestData = {
                Login: payload.login,
                Senha: payload.senha
            };
            apiClient
            .post(urlRequest, requestData)
            .then((response) => {
                const retornoApi = response.data;
                    commit('login', {
                    usuario: retornoApi.Usuario,
                        portais: retornoApi.Portais.map((portal) => {
                            return {
                                Id: portal.Id,
                                Nome: portal.Nome,
                                PortalLabel: `${portal.Id} - ${portal.Nome}`,
                                Empresas: portal.Empresas.map((empresa) => {
                                    return {
                                        Id: empresa.Id,
                                        Nome: empresa.Nome,
                                        EmpresaLabel: `${empresa.Id} - ${empresa.Nome}` + (retornoApi.Usuario.SupervisorRede ? ` (${portal.Id} - ${portal.Nome})` : ``),
                                        IdPortalEmpresa: `${empresa.Id}-${portal.Id}`
                                    };
                                })
                            };
                        }),
                        tokenTemporario: retornoApi.TokenTemporario
                    });

                    commit('atualizarMetodoAutenticacao', {
                        tipo: AUTENTICACAO_WMS,
                        nomeAplicacaoOrigem: ''
                    });

                    resolve();
                })
                .catch((error) => {
                    apiConfig.reset();
                    commit('logout');
                    reject(error);
                });
        });
    },

    loginAnonimo: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/BuscarJWTAnonimoPorChave';

            const requestData = {
                Chave: payload.Chave,
                Produtos: NOMES_PRODUTOS_API
            };

            apiClient
                .post(urlRequest, requestData)
                .then((response) => {
                    const retornoApi = response.data;
                    apiConfig.setTokenAnonimo(retornoApi.TokenJWT);
                    registrarUrlAPIs(retornoApi.ProdutosUrls);
                    commit('setarAutenticado');
                    commit('atualizarMetodoAutenticacao', {
                        tipo: AUTENTICACAO_ANONIMA,
                        nomeAplicacaoOrigem: 'Página Pública'
                    });
                    resolve();
                })
                .catch((error) => {
                    apiConfig.reset();
                    commit('logout');
                    reject(error);
                });
        });
    },

    selecionarEmpresa: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            '';
            const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/SelecionarEmpresa';

            const requestData = {
                IdPortal: payload.IdPortal,
                IdEmpresa: payload.IdEmpresa,
                Produtos: NOMES_PRODUTOS_API
            };

            if (idConfigAmbiente && idConfigAmbiente != 0) {
                requestData.IdAmbienteProdutos = idConfigAmbiente;
            }

            apiClient
                .post(urlRequest, requestData, {
                    headers: { AuthToken: state.tokenTemporarioLogin }
                })
                .then((response) => {
                    const retornoApi = response.data;
                    var portalSelecionado = state.portaisUsuario.filter((p) => p.Id == payload.IdPortal)[0];
                    var empresaSelecionada = portalSelecionado.Empresas.filter((e) => e.Id == payload.IdEmpresa)[0];

                    apiConfig.setToken(retornoApi.TokenJWT);

                    commit('selecionarEmpresa', {
                        Id: empresaSelecionada.Id,
                        Nome: empresaSelecionada.Nome,
                        Portal: portalSelecionado.Id
                    });

                    registrarUrlAPIs(retornoApi.Produtos);

                    resolve();
                })
                .catch((error) => {
                    apiConfig.reset();
                    commit('selecionarEmpresa', null);
                    reject(error);
                });
        });
    },

    logout: ({ commit, dispatch }) => {
        apiConfig.reset();
        commit('logout');
        dispatch('resetAllState', null, { root: true });
    },

    loginPorToken: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/LoginPorToken';

            const requestData = {
                Produtos: NOMES_PRODUTOS_API
            };

            if (idConfigAmbiente) {
                requestData.IdAmbienteProdutos = idConfigAmbiente;
            }

            apiClient
                .post(urlRequest, requestData, {
                    headers: { AuthToken: payload.token }
                })
                .then((response) => {
                    const retornoApi = response.data;
                    commit('login', {
                        usuario: retornoApi.Usuario,
                        portais: [],
                        tokenTemporario: payload.token
                    });

                    registrarUrlAPIs(retornoApi.Produtos);
                    apiConfig.setToken(retornoApi.TokenJWT);

                    commit('selecionarEmpresa', {
                        Id: retornoApi.Empresa.Id,
                        Nome: retornoApi.Empresa.Nome,
                        Portal: retornoApi.Portal.Id
                    });

                    commit('atualizarMetodoAutenticacao', {
                        tipo: AUTENTICACAO_TOKEN,
                        nomeAplicacaoOrigem: payload.nomeAplicacaoOrigem
                    });

                    resolve();
                })
                .catch((error) => {
                    apiConfig.reset();
                    commit('logout');
                    reject(error);
                });
        });
    },

    // eslint-disable-next-line
    recuperarSenha: ({ state, commit }, loginUsuario) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Usuarios/RecuperarSenha';

            apiClient
                .post(urlRequest, `'${loginUsuario}'`)
                .then((response) => {
                    //const retornoApi = response.data;
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default auth_actions;
