import swal from '@/common/alerts';
// eslint-disable-next-line no-unused-vars
export default function install(Vue, { router, store }) {
    //Miscellaneous
    /**
     * Retorna o tipo do objeto de forma textual
     * @param {Object} value - Objeto que será avaliado
     */
    function toRawType(value) {
        let _toString = Object.prototype.toString;

        let str = _toString.call(value);

        return str.slice(8, -1);
    }

    // Error helpers
    function globalErrorHandler(err, customMessage) {
        /* eslint-disable no-console */
        console.log('[Error Handler]', { ...err });

        if (err.redirected) {
            return;
        }

        const message = customMessage || 'Ocorreu um problema ao realizar essa operação';

        const errors = this.$helper.getApiErrors(err);
        if (Array.isArray(errors) && errors.length) {
            errors.forEach((error) => swal.exibirMensagemErro(error));
            return errors;
        } else {
            swal.exibirMensagemErro(message);
        }
    }

    function getApiErrors(err) {
        let errors = [];

        if (err.data && err.data.length) {
            errors = err.data;
        } else if (err.response && err.response.data && err.response.data.length) {
            errors = err.response.data;
        }

        return errors;
    }

    // DateTime
    function isLastDayMonth(ymd) {
        const date = this.$moment(ymd);
        const dayCurrent = date.format('DD');
        return date.endOf('month').format('DD') == dayCurrent;
    }

    function isFirstDayMonth(ymd) {
        const date = this.$moment(ymd);
        const dayCurrent = date.format('DD');
        return date.startOf('month').format('DD') == dayCurrent;
    }

    async function getVideoDevice() {
        let sources = [];
        await navigator?.mediaDevices?.enumerateDevices().then((devices) => {
            devices.forEach((device) => {
                    sources.push(device);
            });
        });

        return sources;
    }

    let api = {};

    Vue.mixin({
        beforeCreate() {
            api = {
                toRawType,
                globalErrorHandler: globalErrorHandler.bind(this),
                getApiErrors,
                isLastDayMonth: isLastDayMonth.bind(this),
                isFirstDayMonth: isFirstDayMonth.bind(this),
                getVideoDevice: getVideoDevice.bind(this)
            };
        },
        created() {
            Vue.$helper = api;
            Vue.prototype.$helper = api;
        }
    });
}
