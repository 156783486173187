import requisicao_wms_actions from './requisicao-wms-actions';
import requisicao_wms_getters from './requisicao-wms-getters';
import requisicao_wms_mutations from './requisicao-wms-mutations';

const requisicao_wms_module = {
    namespaced: true,
    state: {
        requisicoes: [],
        vendedores: [],
        tiposRequisicao: [],
        etapasProcesso: [],
        naturezasOperacao: [],
        permissoesWMS: [],
        statusRequisicao: [],
        tipoSolicitacao: [],
        tiposOperacao: [],
        filtrosRequisicoes: {
            opcaoFiltro: 1,
            tipoPesquisa: null,
            tipoOperacao: null,
            tiposRequisicao: null,
            status: null,
            etapasProcesso: null,
            etapasOperacao: null,
            vendedores: null,
            dataInicial: null,
            dataFinal: null,
            naturezasOperacao: null,
            produto: null,
            clienteFornecedor: { 
                Nome: null, 
                Codigo: null 
            },
            erros: {
                dataInicial: null, 
                dataFinal: null
            },
        },
        consultaRequisicao: null
    },
    getters: requisicao_wms_getters,
    actions: requisicao_wms_actions,
    mutations: requisicao_wms_mutations
};

export default requisicao_wms_module;
