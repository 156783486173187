const removeAllPrototypes = () => {
    delete Array.prototype.selectToList;
    delete Array.prototype.sum;
    delete Array.prototype.toListByProp;
    delete Array.prototype.firstOrDefault;
    delete Array.prototype.any;
};

const insertAllPrototypes = () => {
    Array.prototype.selectToList = function(propName) {
        return this.map(function(obj) {
            return obj[propName];
        });
    };

    Array.prototype.sum = function(key) {
        return this.reduce((a, b) => a + (b[key] || 0), 0);
    };

    Array.prototype.toListByProp = function(propName, value) {
        return this.filter(function(obj) {
            if (obj[propName] == value) {
                return obj;
            }
        });
    };

    Array.prototype.firstOrDefault = function(propName, value) {
        var found = this.toListByProp(propName, value);

        if (found && found.length > 0) {
            return found[0];
        }

        return null;
    };

    Array.prototype.any = function() {
        return this.length > 0;
    };

    Array.nullOrEmpty = function(arr) {
        if (!arr) {
            return true;
        }

        return !arr.any();
    };
};

(function() {
    insertAllPrototypes();
})();

export default {
    removeAllPrototypes,
    insertAllPrototypes
};
