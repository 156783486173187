import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';

import { PRODUTO_ATUALIZA_PRODUTOS, PRODUTO_RESET_PRODUTOS } from './produto-mutations-types';

const produto_actions = {
    async resetProdutos({ commit }) {
        commit(PRODUTO_RESET_PRODUTOS, null);
    },

    async obterProdutos({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/ListarItensLocalizacoesSaldo';
            apiClient
                .post(urlRequest, payload)
                .then((response) => {
                    commit(PRODUTO_ATUALIZA_PRODUTOS, response.data);
                    return resolve(response);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
};

export default produto_actions;
