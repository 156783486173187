const balanco_getters = {
  filtrosBalanco(state) {
    return state.filtros;
  },
  balancoSelecionado(state) {
    return state.balancoSelecionado;
  },
  secaoSelecionada(state) {
    return state.secaoSelecionada;
  },
  listaSecoesBalancoParaExecucao(state) {
    return state.listaSecoesBalancoParaExecucao;
  },
  listaDeBalancosNaoFinalizados(state) {
    return state.listaDeBalancos.filter((balanco) => {
      return balanco.IdSituacao != state.enumSituacaoBalanco.Finalizado;
    });
  },
  listaItensConferidos(state) {
    return state.listaItensConferidos;
  },
  produtoPesquisado(state) {
    return state.produto;
  },
};

export default balanco_getters;
