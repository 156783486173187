import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex-extensions';
import uiState from './modules/uiState';
import appData from './modules/appData';
import auth from './modules/auth';
import produtos from './modules/produto';
import requisicoesWMS from './modules/requisicoes-wms';
import balanco from './modules/balanco';
import cliente from './modules/cliente';
import listaProduto from './modules/listaProduto';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    key: 'wms-store'
});

export default createStore(Vuex.Store, {
    state: {},
    mutations: {},
    actions: {},
    modules: {
        uiState,
        appData,
        auth,
        produtos,
        requisicoesWMS,
        cliente,
        balanco,
        listaProduto
    },
    plugins: [vuexLocal.plugin]
});
