export const REQUISICAO_ATUALIZA_VENDEDORES = 'requisicao_atualizaVendedores';
export const REQUISICAO_ATUALIZA_TIPOS_REQUISICAO = 'requisicao_atualizaTiposRequisicao';
export const REQUISICAO_ATUALIZA_ETAPAS_PROCESSO = 'requisicao_atualizaEtapasProcessosRequisicao';
export const REQUISICAO_ATUALIZA_NATUREZAS_OPERACAO = 'requisicao_atualizaNaturezasOperacao';
export const REQUISICAO_ATUALIZA_PERMISSOES_WMS = 'requisicao_atualizaPermissoesWMS';
export const REQUISICAO_ATUALIZA_STATUS_REQUISICAO = 'requisicao_atualizaStatusRequisicao';
export const REQUISICAO_ATUALIZA_TIPO_SOLICITACAO = 'requisicao_atualizaTipoSolicitacao';
export const REQUISICAO_ATUALIZA_TIPOS_OPERACAO = 'requisicao_atualizaTiposOperacao';
export const REQUISICAO_ATUALIZA_REQUISICOES_WMS = 'requisicao_atualizaListaRequisicoesWMS';
export const REQUISICAO_ATUALIZA_FILTROS_REQUISICOES_WMS = 'requisicao_atualizaFiltrosRequisicoesWMS';
export const REQUISICAO_CONSULTA_REQUISICAO_WMS = 'requisicao_consultaRequisicaoWMS';
