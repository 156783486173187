const STORAGEKEY_TOKEN = 'token_api';
const STORAGEKEY_TOKEN_ANONIMO = 'token_api_anonimo';
const STORAGEKEY_URL_API_CRM = 'url_crm_api';
const STORAGEKEY_URL_API_SUPRIMENTOS = 'url_suprimentos_api';

const urlSuprimentosApiDev = process.env.VUE_APP_URL_SUPRIMENTOSAPI;
const urlCrmApiDev = process.env.VUE_APP_URL_CRMAPI;

const apiConfig = {
    getToken: () => localStorage.getItem(STORAGEKEY_TOKEN) || '',

    getTokenAnonimo: () => localStorage.getItem(STORAGEKEY_TOKEN_ANONIMO) || '',
    getUrlCrmApi: () => urlCrmApiDev || localStorage.getItem(STORAGEKEY_URL_API_CRM) || '',
    getUrlSuprimentosApi: () => urlSuprimentosApiDev || localStorage.getItem(STORAGEKEY_URL_API_SUPRIMENTOS) || '',

    getUrlErpAdminApi: () => process.env.VUE_APP_URL_ERPADMIN,

    setToken: (val) => {
        if (!val) localStorage.removeItem(STORAGEKEY_TOKEN);
        else localStorage.setItem(STORAGEKEY_TOKEN, val);
    },

    setTokenAnonimo: (val) => {
        if (!val) localStorage.removeItem(STORAGEKEY_TOKEN_ANONIMO);
        else localStorage.setItem(STORAGEKEY_TOKEN_ANONIMO, val);
    },
    setUrlCrmApi: (val) => {
        if (!val) localStorage.removeItem(STORAGEKEY_URL_API_CRM);
        else localStorage.setItem(STORAGEKEY_URL_API_CRM, val);
    },

    setUrlSuprimentosApi: (val) => {
        if (!val) localStorage.removeItem(STORAGEKEY_URL_API_SUPRIMENTOS);
        else localStorage.setItem(STORAGEKEY_URL_API_SUPRIMENTOS, val);
    },

    reset: () => {
        localStorage.removeItem(STORAGEKEY_TOKEN);
        localStorage.removeItem(STORAGEKEY_URL_API_CRM);
        localStorage.removeItem(STORAGEKEY_URL_API_SUPRIMENTOS);
    }
};

export default apiConfig;
