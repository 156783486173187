import auth_actions from './auth-actions';
import auth_getters from './auth-getters';
import auth_mutations from './auth-mutations';

const auth_module = {
    namespaced: true,
    state: {
        usuario: null,
        empresa: null,
        autenticado: false,
        tokenTemporarioLogin: null,
        LmxAuthToken: null,
        portaisUsuario: null,
        metodoAutenticacao: {
            tipo: null,
            nomeAplicacaoOrigem: null
        },
        permissoesWMS: null
    },
    getters: auth_getters,
    actions: auth_actions,
    mutations: auth_mutations
};

export default auth_module;
