/* eslint-disable no-unused-vars */
import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';
import swal from '@/common/alerts';

export const getDefaultState = () => {
    return {
        dadosProdutos: []
    };
};

const state = getDefaultState();

const mutations = {};

const getters = {
    dadosProdutos: () => {
        return state.dadosProdutos;
    },   
};

const actions = {
    Autocompletar: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/PesquisarProdutos';

            apiClient
                .post(urlRequest, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    swal.exibirMensagemErro(err.response.data);
                    reject(err);
                });
        });
    },

    btnSair_Click: function() {
        state.ultimoDocumentoPesquisado = '';
        this.$emit('input', { salvou: false, dados: null });
    },

    sair: function() {
        Object.assign(state, getDefaultState());
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
